import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  list: {
    width: 250,
  }
});

export default function NavDrawer(props) {
  const toggle = props.isToggled;
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggle(open);
  };

  const list = () => (
    <div
      className={classes.list}
    >
      <List>
        <Link to="/">
          <ListItem>
            <ListItemText primary={'Home'} />
          </ListItem>
        </Link>
        <Link to="projects">
          <ListItem>
            <ListItemText primary={'Projects'} />
          </ListItem>
        </Link>
        <Link to="/events">
          <ListItem>
            <ListItemText primary={'Events'} />
          </ListItem>
        </Link>
        <Link to="tnt-academy">
          <ListItem>
            <ListItemText primary={'TNT Academy'} />
          </ListItem>
        </Link>
        <Link to="/team">
          <ListItem>
            <ListItemText primary={'Meet the Team'} />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <>
      <Drawer anchor='right' open={true} onClose={() => toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
}