import React, { useState, useEffect } from 'react';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  ListItem,
  ListItemText,
  List,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import NavDrawer from './drawer';
import { Link, useLocation } from "react-router-dom";
import whiteFavicon from "../../images/white-logo.svg";
import blueFavicon from "../../images/blue-logo.svg";
import useWindowSize from '../hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  padleft: {
    paddingLeft: '3px'
  },
  bar: {
    backgroundColor: "white",
    color: "black"
  },
  barList: {
    display: "flex"
  }, 
  transparentBar: {
    backgroundColor: 'transparent',
    color: 'white', 
    boxShadow: 'none',
  },
  linkStyles: { 
    textDecoration: 'none'
  }, 
  imgStyles: {
    width: '60px', 
    height: '50px'
  }
}));

function Navbar() {
  const classes = useStyles()
  const size = useWindowSize()
  var location = useLocation();
  const [state, setState] = useState(false)
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    const currentPath = location.pathname;
    // Only apply transparent navbar to home page
    if (currentPath === '/' && window.scrollY <= 250) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };


  useEffect(() => {
    changeBackground(); // Make sure it's called once component is mounted/location changes

    window.addEventListener('scroll', changeBackground);

    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [location]);

  return <>
    <AppBar className={navbar ? classes.transparentBar : classes.bar} position='fixed'>
      <Toolbar>
        <Link to="/">
          <img src={navbar ? whiteFavicon : blueFavicon} alt="ntxbrain" className={classes.imgStyles} />
        </Link>
        <Typography variant='h6' className={`${classes.title} ${classes.padleft}`}>
          {/* <Link to="/" className={classes.linkStyles}>Triton NeuroTech</Link> - Comment out for now but keep typography to maintain spacing */}
        </Typography>
        {size.width > 1200 &&
          <List className={classes.barList}>
            <Link to="/" className={classes.linkStyles}>
              <ListItem>
                <ListItemText primary={'Home'} />
              </ListItem>
            </Link>
            <Link to="projects" className={classes.linkStyles}>
              <ListItem>
                <ListItemText primary={'Projects'} className={classes.linkStyles} />
              </ListItem>
            </Link>
            <Link to="/events" className={classes.linkStyles}>
              <ListItem>
                <ListItemText primary={'Events'} className={classes.linkStyles} />
              </ListItem>
            </Link>
            <Link to="tnt-academy" className={classes.linkStyles}>
              <ListItem>
                <ListItemText primary={'TNT Academy'} />
              </ListItem>
            </Link>
            <Link to="/team" className={classes.linkStyles}>
              <ListItem>
                <ListItemText primary={'Meet the Team'} className={classes.linkStyles} />
              </ListItem>
            </Link>
          </List>
        }
        {size.width <= 1200 &&
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setState(!state)}
            size="large">
            <MenuIcon />
            {
              state && <NavDrawer isToggled={setState}></NavDrawer>
            }
          </IconButton>
        }
      </Toolbar>
    </AppBar>
  </>;
}

export default Navbar