import React from "react";
import eventData from "./eventData.json";
import EventCard from "./event-card";
import { Container, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Contact from "../landing/contact.js";

const useStyles = makeStyles((theme) => ({
  eventsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginTop: "7vh",
  },
  fontStyles: {
    fontFamily: theme.typography.fontFamily,
  },
  contentContainer: {
    textAlign: "center",
  },
  icon: {
    fontSize: "60px",
  },
  greyColor: {
    color: "#005678",
  },
  fontBold: {
    fontWeight: "bold",
  },
  header: {
    fontSize: "48px",
    color: theme.palette.primary.dark,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    width: "100%",
    padding: "20px",
  },
  dividerStyle: {
    backgroundColor: "#00B192",
    width: "10vw",
    height: "0.5vw",
    borderRadius: "5px",
    margin: "100px 0",
  },
  // Styling for mobile devices
  "@media (max-width: 600px)": {
    eventsContainer: {
      marginTop: "18vh",
    },
  },
}));

// Events page that maps all the event data to event cards
function Events() {
  // Scroll to top of page on load
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  // Get the current date
  const currentDate = new Date();

  // Filter events into past and upcoming
  const pastEvents = eventData.events
    .filter((event) => new Date(event.date) < currentDate)
    .sort((a, b) => new Date(b.date) - new Date(a.date)); // Show most recent past events to oldest past events
  const upcomingEvents = eventData.events
    .filter((event) => new Date(event.date) >= currentDate)
    .sort((a, b) => new Date(a.date) - new Date(b.date)); // Show events closest today to furthest from today

  return (
    <>
      <Container maxWidth={false} className={classes.eventsContainer}>
        {/*Upcoming events*/}
        <h1 className={`${classes.header} ${classes.fontStyles}`}>
          Upcoming Events
        </h1>
        <div className={classes.row}>
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((data, index) => (
              <EventCard
                key={index}
                name={data.name}
                location={data.location}
                date={data.date}
                formattedDate={data.formattedDate}
                description={data.description}
                imgSrc={data.imgSrc}
                imgAlt={data.imgAlt}
                link={data.link}
                purpose={data.purpose}
              />
            ))
          ) : (
            <h4 className={classes.fontStyles}>
              Stay tuned for upcoming events!
            </h4>
          )}
        </div>
        <Divider className={classes.dividerStyle} />
        {/*Past events*/}
        <h1 className={`${classes.header} ${classes.fontStyles}`}>
          Past Events
        </h1>
        <div className={classes.row}>
          {pastEvents.map((data, index) => (
            <EventCard
              key={index}
              name={data.name}
              location={data.location}
              date={data.date}
              formattedDate={data.formattedDate}
              description={data.description}
              imgSrc={data.imgSrc}
              imgAlt={data.imgAlt}
              link={data.link}
              purpose={data.purpose}
            />
          ))}
        </div>
      </Container>
      <Contact />
    </>
  );
}

export default Events;
