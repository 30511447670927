import { Button, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { React, useState } from "react";
import ProjectCard from "./project-card";
import projectInfo from "./projectInfo.json"; 
import Contact from "../landing/contact";

const useStyles = makeStyles((theme) => ({
    contactBg: {
        padding: '80px 50px 0px 50px',
        minHeight: '100vh',
        width: '100vw'
    },
    subheader: { 
        fontFamily: 'inherit',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    largeText: {
        fontSize: '40px',
        paddingBottom: '30px'
    },
    button: { 
        marginBottom: '50px', 
        // padding: '10px 20px', 
        backgroundColor: theme.palette.primary.light, 
        color: theme.palette.primary.main, 
        borderColor: theme.palette.primary.main, 
        // borderRadius: '10px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.light, 
        },
    }
}));

function Projects() {
    const classes = useStyles(); 

    const [showAllAxon, setShowAllAxon] = useState(false);
    const [showAllDendrite, setShowAllDendrite] = useState(false);

    const [dendriteMessage, setDendriteMessage] = useState("See More");
    const [axonMessage, setAxonMessage] = useState("See More")

    const axonProjects = projectInfo.current_projects.filter(project => project.project_type === 'axon');
    const dendriteProjects = projectInfo.current_projects.filter(project => project.project_type === 'dendrite');

    const visibleAxonProjects = showAllAxon ? axonProjects : axonProjects.slice(0, 3);
    const visibleDendriteProjects = showAllDendrite ? dendriteProjects : dendriteProjects.slice(0, 3);

    const handleAxonButtonClick = () => {
        setShowAllAxon(!showAllAxon);
        if (axonMessage === "See More") { 
            setAxonMessage("See Less"); 
        } else { 
            setAxonMessage("See More"); 
        }
    }

    const handleDendriteButtonClick = () => { 
        setShowAllDendrite(!showAllDendrite);
        if (dendriteMessage === "See More") { 
            setDendriteMessage("See Less"); 
        } else { 
            setDendriteMessage("See More"); 
        }
    }
    return(
        <>
            <Container maxWidth='false' className={`${classes.contactBg} ${classes.column}`}>
                <div className={classes.largeText}><i>Projects</i></div>
                <h2 className={classes.subheader}>Axon Projects</h2>
                <div className={classes.column}>
                    {visibleAxonProjects.map((data, index) =>
                        <ProjectCard key={index} name={data.name} description={data.description} imgSrc={data.imgSrc} imgAlt={data.imgAlt} link={data.link} />
                    )}
                    {axonProjects.length > 3 && (
                        <Button variant='outlined' size='large' className={classes.button} onClick={handleAxonButtonClick}>{axonMessage}</Button>
                    )}
                </div>
                <h2 className={classes.subheader}>Dendrite Projects</h2>
                <div className={classes.column}>
                    {visibleDendriteProjects.map((data, index) =>
                        <ProjectCard key={index} name={data.name} description={data.description} imgSrc={data.imgSrc} imgAlt={data.imgAlt} />
                    )}
                    {dendriteProjects.length > 3 && (
                        <Button variant='outlined' size='large' className={classes.button} onClick={handleDendriteButtonClick}>{dendriteMessage}</Button>
                    )}
                </div>
            </Container>
            <Contact />
        </>
    )
}

export default Projects