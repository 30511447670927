import React from 'react';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: '20px',
        width: '80vw',
        height: '25vw',
        borderRadius: '30px',
        border: '5px solid',
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.light,
        color: theme.palette.primary.dark
    },
    desktop: { 
        display: 'block'
    },
    fontStyles: { 
        fontFamily: theme.typography.fontFamily
    },
    subContainer: {
        width: '50%',
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column'
    },
    row: { 
        display: 'flex', 
        flexDirection: 'row'
    },
    boldWords: {
        fontWeight: 'bold'
    },
    subtitle: { 
        fontSize: '2.2vw'
    },
    text: { 
        fontSize: '1.1vw'
    },
    eventThumbnail: {
        width: '100%',
    },
    divider: {
        backgroundColor: theme.palette.primary.dark,
        width: '3px',
        height: '3vw'
    },
    buttonStyling: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.light,
        width: '13vw',
        fontSize: '1vw',
        '&:hover': {
            background: theme.palette.primary.light,
            color: theme.palette.primary.main
        },
    },
    center: {
        display: 'flex', 
        alignItems: 'center',
    },
    padding: { 
        padding: '1vw'
    },
    bottomMargin: { 
        bottomMargin: '1.5vw'
    }, 
    rightSpacing: { 
        marginRight: '10px' 
    },
    '@media (max-width: 900px)': {
        cardContainer: { 
            flexDirection: 'column',
            height: '50vw',
        }, 
        desktop: { 
            display: 'none'
        },
        subContainer: {
            width: '90%'
        },
        eventThumbnail: {
            width: '50%',
            display: 'flex',
            justifyContent: 'center'
        },
        divider: { 
            height: '5vw'
        }, 
        subtitle: { 
            fontSize: '4vw'
        }, 
        text: { 
            fontSize: '2.5vw'
        }, 
        bottomMargin: { 
            bottomMargin: '2vw'
        }, 
        buttonStyling: { 
            width: '30vw',
            fontSize: '2vw'
        }
    }
}));


function ProjectCard(props) {
    const { name, description, imgSrc, imgAlt, link } = props
    const classes = useStyles()

    return (
        <Card variant="outlined" className={classes.cardContainer}>
            <div className={`${classes.subContainer} ${classes.desktop}`}>
                <img className={classes.eventThumbnail} src={imgSrc} alt={imgAlt} />
            </div>
            <div className={`${classes.subContainer} ${classes.padding}`}>
                <div className={`${classes.bottomMargin} ${classes.row} ${classes.center}`}>
                    <Divider orientation="vertical" className={`${classes.divider} ${classes.rightSpacing}`}/>
                    <h1 className={`${classes.boldWords} ${classes.fontStyles} ${classes.subtitle}`}>{name}</h1>
                </div>
                <p className={`${classes.bottomMargin} ${classes.text}` }>{description}</p>
                { link ? (
                    <a href={link} target='_blank' rel="noreferrer">
                        <Button variant='contained' size='large' className={classes.buttonStyling}>
                            Learn More
                        </Button>
                    </a>
                ) : null }
            </div>
        </Card>
    );
}

export default ProjectCard; 