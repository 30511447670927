import Navbar from './components/navbar/navbar';
import Team from './components/team/team';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Projects from './components/projects/projects';
// import Project from './components/projects/project';
import Landing from './components/landing/landing';
import Workshops from './components/workshops';
import Networking from './components/networking';
import Events from './components/events/events';
import TNTAcademy from './components/tnt-academy/tnt-academy';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/:sectionId" element={<Landing />} />
        <Route path="team" element={<Team />} />
        <Route path="projects" element={<Projects />} />
        {/* <Route path="projects/:projectId" element={<Project />} /> */}
        <Route path="workshops" element={<Workshops />} />
        <Route path="networking" element={<Networking />} />
        <Route path="events" element={<Events />} />
        <Route path="tnt-academy" element={<TNTAcademy />} />
      </Routes>
    </Router>
  );
}

export default App;