import React from "react";
import { Container, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import background from '../../images/website_background.png';
import brain from '../../images/home-brain.svg'

const useStyles = makeStyles((theme) => ({
  homeBg: {
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // backgroundImage: `url(${background})`, Update to have the color scheme later
    backgroundColor: theme.palette.primary.main, 
    minHeight: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'white',
    width: '100vw', 
    padding: '0', 
  },
  mobile: {
    display: 'none'
  },
  largeText: {
    fontSize: '70px'
  }, 
  homeContainer: { 
    display: 'flex', 
    flexDirection: 'row', 
    marginTop: '10vw'
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
    width: '10px',
    height: '200px'
  }, 
  row: { 
    display: 'flex', 
    flexDirection: 'row'
  }, 
  center: { 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'center'
  }, 
  header: { 
    fontFamily: theme.typography.fontFamily,
    fontSize: '72px', 
    marginLeft: '20px'
  }, 
  spacingBetween: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%' 
  }, 
  rightSpacing: { 
    marginRight: '100px' 
  },
  icon: {
    fontSize: '48px', 
    padding: '0'
  },
  bottomIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px', 
    marginTop: '40px', 
    cursor: 'pointer'
  },
  imgStyles: { 
    width: '35vw'
  },
  '@media (max-width: 900px)': {
    homeContainer: { 
      flexDirection: 'column'
    }, 
    desktop: { 
      display: 'none'
    },
    mobile: { 
      display: 'block'
    }, 
    header: { 
      fontSize: '36px', 
      paddingTop: '10px'
    },
    imgStyles: { 
      width: '60vw'
    },
  }
}));

function Home() {
  const classes = useStyles()

  const scrollToGetInvolved = () => {
    const getInvolvedSection = document.getElementById('about'); 
    getInvolvedSection.scrollIntoView({ behavior: 'smooth' }); 
  };

  return (
    <Container maxWidth='false' className={classes.homeBg}>
      <div className={`${classes.homeContainer} ${classes.center} ${classes.spacingBetween}`}>
        <img src={brain} alt="brain-group-pic" className={classes.imgStyles} />
        <div className={`${classes.desktop} ${classes.row} ${classes.center} ${classes.rightSpacing}`}>
          <Divider orientation="vertical" className={classes.divider}/>
          <h1 className={classes.header}>Triton<br /><strong>NeuroTech</strong></h1>
        </div>
        <h1 className={`${classes.mobile} ${classes.header}`}>Triton <strong>NeuroTech</strong></h1>
      </div>
      <div className={`${classes.bottomIconContainer} ${classes.desktop}`} onClick={scrollToGetInvolved}>
        <KeyboardArrowDownIcon className={classes.icon} />
      </div>
    </Container>
  );
}

export default Home;