import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Contact from "./landing/contact.js";

const useStyles = makeStyles((theme) => ({
    workshopContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    contentContainer: {
        textAlign: 'center',
    },
    icon: {
        fontSize: '60px'
    },
    greyColor: {
        color: '#005678'
    },
    fontBold: {
        fontWeight: 'bold'
    }
}));

function Workshops() {
    const classes = useStyles()

    return (
        <Container maxWidth={false} className={classes.workshopContainer} >
            <div className={classes.contentContainer}>
                <ConstructionIcon className={`${classes.icon} ${classes.greyColor}`} />
                <h1 className={classes.fontBold}>Under Construction</h1>
                <p>Please check back soon!</p>
            </div>
            <Contact />
        </Container>
    );
}

export default Workshops; 