import React from "react";
import { Button, Container, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LaptopIcon from "@mui/icons-material/Laptop";
import BuildIcon from "@mui/icons-material/Build";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import People from "@mui/icons-material/People";
import Divider from "@mui/material/Divider";
import TNT_Lab from "../../images/TNT_lab.jpg";

const useStyles = makeStyles((theme) => ({
  getInvolvedContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "80px 0 100px 0",
  },
  mobile: {
    display: "none",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  getInvolvedButtons: {
    borderRadius: "1rem",
    margin: "1.5rem 5rem",
    width: "20rem",
  },
  marginTop: {
    marginTop: "80px",
  },
  marginBotton: {
    marginBottom: "80px",
  },
  header: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "36px",
  },
  padding: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  image: {
    width: "30%",
    height: "20%",
    marginLeft: "7rem",
    borderRadius: "1rem",
  },
  bigButtons: {
    display: "flex",
    flexDirection: "column",
    padding: "3rem 0 !important",
    borderRadius: "2rem",
  },
  buttonText: {
    fontWeight: "bold",
    marginTop: "20px",
    textTransform: "none",
  },
  smallText: {
    fontSize: "16px",
  },
  mediumText: {
    fontSize: "20px",
  },
  icon: {
    fontSize: "36px",
  },
  linkStyling: {
    textDecoration: "none",
  },
  dividerStyle: {
    backgroundColor: "#00B192",
    width: "10vw",
    height: "0.5vw",
    borderRadius: "5px",
  },
  "@media (max-width: 900px)": {
    row: {
      flexDirection: "column",
    },
    padding: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    mobile: {
      display: "block",
    },
    desktop: {
      display: "none",
    },
    image: {
      width: "60%",
      height: "40%",
      margin: "0 0 3rem 0",
    },
  },
}));

function GetInvolved() {
  const classes = useStyles();
  return (
    <Container maxWidth="false" className={classes.getInvolvedContainer}>
      <div className={classes.row}>
        <h3 className={classes.header}>We are Triton NeuroTech.</h3>
        <p
          className={`${classes.padding} ${classes.mediumText} ${classes.marginBotton}`}
        >
          We are the NeuroTechX student club chapter at UC San Diego. Here at
          Triton NeuroTech, we focus on diving into the many topics needed to
          advance the technologies that allow us to learn about and interface
          with our brains. We host workshops and projects to help those
          interested get into the field, invite guests from industry and
          research to provide networking opportunities, and foster a community
          that values learning and helping each other grow. If you're interested
          in brains, cognition, engineering, and data science, you'll fit right
          in!
        </p>
      </div>
      <Divider className={classes.dividerStyle} />
      <h1 className={`${classes.header} ${classes.marginTop}`}>
        Get Involved!
      </h1>
      <div className={classes.row}>
        <Button
          variant="contained"
          size="large"
          className={`${classes.getInvolvedButtons} ${classes.buttonText} ${classes.mediumText}`}
          href="https://discord.com/invite/xvzYKh3"
          target="_blank"
        >
          Join Our Discord
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${classes.getInvolvedButtons} ${classes.buttonText} ${classes.mediumText}`}
          href="http://eepurl.com/humOu1"
          target="_blank"
        >
          Mailing List Sign Up
        </Button>
        <Link to="/events" className={classes.linkStyling}>
          <Button
            variant="contained"
            size="large"
            className={`${classes.getInvolvedButtons} ${classes.buttonText} ${classes.mediumText}`}
          >
            Upcoming Events
          </Button>
        </Link>
        <Button
          variant="contained"
          size="large"
          className={`${classes.getInvolvedButtons} ${classes.buttonText} ${classes.mediumText}`}
          href="https://forms.gle/FjJn1UW7wYD5DLnK6"
          target="_blank"
        >
          Dendrite Project Form
        </Button>
        <Button
          variant="contained"
          size="large"
          className={`${classes.getInvolvedButtons} ${classes.buttonText} ${classes.mediumText}`}
          href="https://forms.gle/n6dgEB5Fb7zMLj4k8"
          target="_blank"
        >
          Axon Project Form
        </Button>
      </div>
      <div className={classes.marginTop}></div>
      <Divider className={classes.dividerStyle} />
      <div className={classes.marginTop}>
        <div className={classes.row}>
          <a
            href="https://aware-toothpaste-9f2.notion.site/Home-TNT-Academy-8a67e74884254289b6aa65730574a298"
            rel="noreferrer"
            target="_blank"
            className={classes.linkStyling}
          >
            <Button
              variant="contained"
              size="large"
              className={`${classes.getInvolvedButtons} ${classes.bigButtons}`}
            >
              <LibraryBooksIcon className={classes.icon} />
              <Typography className={classes.buttonText}>
                TNT Academy
              </Typography>
            </Button>
          </a>
          <a href="projects" className={classes.linkStyling}>
            <Button
              variant="contained"
              size="large"
              className={`${classes.getInvolvedButtons} ${classes.bigButtons}`}
            >
              <BuildIcon className={classes.icon} />
              <Typography className={classes.buttonText}>Projects</Typography>
            </Button>
          </a>
          <Link to="/events" className={classes.linkStyling}>
            <Button
              variant="contained"
              size="large"
              className={`${classes.getInvolvedButtons} ${classes.bigButtons}`}
            >
              <ConfirmationNumberIcon className={classes.icon} />
              <Typography className={classes.buttonText}>Events</Typography>
            </Button>
          </Link>
          <Link to="/networking" className={classes.linkStyling}>
            <Button
              variant="contained"
              size="large"
              className={`${classes.getInvolvedButtons} ${classes.bigButtons}`}
            >
              <People className={classes.icon} />
              <Typography className={classes.buttonText}>Networking</Typography>
            </Button>
          </Link>
        </div>
      </div>
    </Container>
  );
}

export default GetInvolved;
