import { Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const useStyles = makeStyles((theme) => ({
    contactBg: {
        height: '35vh',
        width: '100vw',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '400px',
        marginBottom: '20px'
    },
    largeText: {
        fontSize: '40px',
        paddingBottom: '30px'
    },
    noBorder: {
        border: "none"
    },
}));

function Contact() {
    const classes = useStyles()
    return (
        <>
            <Container maxWidth='false' className={`${classes.contactBg} ${classes.column}`}>
                <div className={classes.largeText}>Contact Us!</div>
                <div className={classes.row}>
                    <a href="https://tinyurl.com/ntx-ucsd-youtube" rel="noreferrer" target="_blank">
                        <i className="fa fa-youtube fa-fw w3-xxlarge"></i>
                    </a>
                    <a href="https://www.facebook.com/tntucsd" rel="noreferrer" target="_blank">
                        <i className="fa fa-facebook-official fa-fw w3-xxlarge"></i>
                    </a>
                    <a href="https://www.instagram.com/tntucsd/" rel="noreferrer" target="_blank">
                        <i className="fa fa-instagram fa-3x" aria-hidden="true"></i>
                    </a>
                    <a href="https://tinyurl.com/ntx-ucsd-discord" rel="noreferrer" target="_blank">
                        <i className="fab fa-discord fa-fw w3-xxlarge"></i>
                    </a>
                    {/* <a href="https://forms.gle/Q2jS85d7uLiL7DR36" rel="noreferrer" target="_blank">
                    <i className="fab fa fa-comments fa-fw w3-xxlarge"></i>
                </a> */}
                </div>
            </Container>
        </>
    )
}

export default Contact