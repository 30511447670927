import React from 'react';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: '20px',
        padding: '40px',
        width: '80vw',
        borderRadius: '30px',
        background: theme.palette.primary.main,
        color: theme.palette.primary.light
    },
    fontStyles: { 
        fontFamily: theme.typography.fontFamily
    },
    subContainer: {
        width: '45%'
    },
    boldWords: {
        fontWeight: 'bold'
    },
    eventThumbnail: {
        // height: '250px',
        // width: '95%',
        // borderRadius: '15px',
        width: '93%',
        display: 'flex',
        justifyContent: 'center'
    },
    dividerStyling: {
        backgroundColor: theme.palette.primary.light,
        width: '2px'
    },
    buttonStyling: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
        '&:hover': { 
            background: theme.palette.primary.main,
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light
        }
    },
    center: {
        textAlign: 'center'
    },
    '@media (max-width: 900px)': {
        cardContainer: { 
            flexDirection: 'column'
        }, 
        subContainer: {
            width: '90%'
        }
    }
}));


function EventCard(props) {
    const { name, description, formattedDate, location, imgSrc, imgAlt, link, purpose } = props
    const classes = useStyles()

    return (
        <Card variant="outlined" className={classes.cardContainer}>
            <div className={classes.subContainer}>
                <img className={classes.eventThumbnail} src={imgSrc} alt={imgAlt} />
            </div>
            <Divider orientation='vertical' flexItem className={classes.dividerStyling} />
            {/* This is the visible format of the event card */}
            <div className={`${classes.subContainer} ${classes.center}`}>
                <h1 className={`${classes.boldWords} ${classes.fontStyles}`}>{name}</h1>
                <h5 className={classes.fontStyles}><strong>Date</strong>: {formattedDate}</h5>
                <h5 className={classes.fontStyles}><strong>Location</strong>: {location}</h5>
                <p>{description}</p>
                { purpose ? (
                    <a href={link} target='_blank' rel="noreferrer">
                        <Button variant='outlined' size='large' className={classes.buttonStyling}>
                            {purpose}
                        </Button>
                    </a>
                ) : null }
            </div>
        </Card>
    );
}

export default EventCard; 