import { Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from "react";

const useStyles = makeStyles((theme) => ({
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px',
        borderRadius: '10px', 
        border: '5px solid',
        borderColor: theme.palette.primary.main
    },
    thumbImg: {
        width: '300px',
        height: 'auto',
        aspectRatio: '1',
        borderRadius: '5px 5px 0 0'
    },
    fontStyles: { 
        fontFamily: theme.typography.fontFamily
    }, 
    textContainer: { 
        textAlign: 'center',
        padding: '5px 15px'
    }
}))

export default function TeamCard(props) {
    const { name, img, position, link, major } = props // Info on each member's team card
    const classes = useStyles()
    return (
        <>
            <Paper className={classes.column}>
                <img src={img} alt={name} className={classes.thumbImg}></img>
                <div className={classes.textContainer}>
                    <h4 className={classes.fontStyles}><strong>{name}</strong></h4>
                    <p className={`${classes.fontStyles} ${classes.padding} ${classes.majorStyle}`}>{major}</p>
                    <h5 className={classes.fontStyles}>{position}</h5>
                </div>
                <a href={link} rel="noreferrer" target="_blank">
                    <LinkedInIcon />
                </a>
            </Paper>
        </>
    )
}