import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FuturaTTF from './fonts/Futura_PT_Medium.ttf';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#00629b',
      dark: '#000'
    },
    secondary: {
      main: '#595959',
    },
  },
  typography: {
    fontFamily: "Futura PT Medium",
    h1: {
      fontSize: '2.5rem'
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.8rem'
    },
    h4: {
      fontSize: '1.6rem'
    }, 
    h5: { 
      fontSize: '1.8rem'
    },
    p: { 
      fontSize: '1rem'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Futura PT Medium",
          src: `url(${FuturaTTF}) format("truetype")`
        },
        h1: {
          fontFamily: "Futura PT Medium",
        },
        h2: {
          fontFamily: "Futura PT Medium",
        },
        h3: {
          fontFamily: "Futura PT Medium",
        },
        h4: {
          fontFamily: "Futura PT Medium",
        }, 
        h5: { 
          fontFamily: "Futura PT Medium",
        },
        p: { 
          fontFamily: "Futura PT Medium",
        }, 
        div: { 
          fontFamily: "Futura PT Medium",
        }, 
        a: {
          fontFamily: "Futura PT Medium",
        }
      }
    }
  }
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
