import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Contact from '../landing/contact';
import Flyer from '../../images/tnt-academy/tnt_academy_flyer.svg'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5vh', 
        padding: '0'
    },
    masthead: { 
        display: 'flex',
        flexDirection: 'column', 
        padding: '5vw',
        backgroundColor: theme.palette.primary.main
    }, 
    subContainer: { 
        width: '40vw'
    }, 
    icon: {
        fontSize: '60px'
    },
    row: { 
        display: 'flex', 
        flexDirection: 'row'
    }, 
    column: { 
        display: 'flex', 
        flexDirection: 'column'
    }, 
    whiteColor: { 
        color: '#ffffff'
    }, 
    greyColor: {
        color: '#005678'
    },
    blackColor: { 
        color: '#000000'
    },
    whiteBackground: { 
        backgroundColor: '#ffffff'
    }, 
    blackBackground: { 
        backgroundColor: '#000000'
    }, 
    fontBold: {
        fontWeight: 'bold'
    }, 
    center: { 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    alignLeft: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    header: { 
        fontFamily: theme.typography.fontFamily,
        fontSize: '48px', 
        marginLeft: '20px'
    }, 
    spacingBetween: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%' 
    }, 
    rightSpacing: { 
        marginRight: '100px' 
    },
    bottomSpacing: { 
        marginBottom: '50px'
    }, 
    topSpacing: { 
        marginTop: '80px'
    }, 
    padding: { 
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10)
    }, 
    fullWidth: { 
        width: '100%'
    },
    verticalDivider: {
        width: '8px',
        height: '70px'
    }, 
    horizontalDivider: { 
        backgroundColor: '#00B192',
        width: '10vw',
        height: '0.5vw', 
        borderRadius: '5px', 
        margin: '60px 0'
    },
    imgStyles: { 
        width: '22vw'
    },
    '@media (max-width: 900px)': {
        desktop: { 
            display: 'none'
        },
        mobile: { 
            display: 'block'
        }, 
        header: { 
            fontSize: '36px', 
            paddingTop: '10px'
        },
        imgStyles: { 
            width: '40vw'
        },
    }
}));

function TNTAcademy() {
    const classes = useStyles()

    return (
        <>
            <Container maxWidth={false} className={classes.container} >
                <div className={`${classes.desktop} ${classes.alignLeft} ${classes.masthead}`}>
                    <div className={`${classes.row} ${classes.rightSpacing} ${classes.bottomSpacing} ${classes.center}`}>
                        <Divider orientation="vertical" className={`${classes.verticalDivider} ${classes.whiteBackground}`}/>
                        <h1 className={`${classes.header} ${classes.whiteColor}`}>About TNT Academy</h1>
                    </div>
                    <div className={`${classes.center} ${classes.row} ${classes.spacingBetween}`}>
                        <img src={Flyer} alt="tnt-academy-flyer" className={`${classes.imgStyles} ${classes.subContainer}`} />
                        <p className={`${classes.subContainer} ${classes.whiteColor}`}>We are a sub-division of UC San Diego’s neurotechnology club, Triton NeuroTech (TNT), focused on science and technology education. We teach neurotechnology content through many different avenues. Our goal is to make learning complex topics less daunting and more fun! Right now, we are focused on building up our content by creating new lessons around common neurotech topics designed to be self-guided and adaptable for in person workshops. In the future, we hope to be able to pair these lessons and workshops with full length videos to promote in-depth learning. Stay tuned to see what we come up with!</p>
                    </div>
                </div>
                <p className={`${classes.mobile} ${classes.blackColor} ${classes.topSpacing} ${classes.padding}`}>We are a sub-division of UC San Diego’s neurotechnology club, Triton NeuroTech (TNT), focused on science and technology education. We teach neurotechnology content through many different avenues. Our goal is to make learning complex topics less daunting and more fun! Right now, we are focused on building up our content by creating new lessons around common neurotech topics designed to be self-guided and adaptable for in person workshops. In the future, we hope to be able to pair these lessons and workshops with full length videos to promote in-depth learning. Stay tuned to see what we come up with!</p>
                <div className={`${classes.center} ${classes.column} ${classes.padding}`}> 
                    <Divider className={classes.horizontalDivider}/>
                    <div className={`${classes.alignLeft} ${classes.column} ${classes.fullWidth}`}>
                        <div className={`${classes.row} ${classes.rightSpacing} ${classes.bottomSpacing} ${classes.center}`}>
                            <Divider orientation="vertical" className={`${classes.verticalDivider} ${classes.blackBackground}`}/>
                            <h1 className={`${classes.header} ${classes.blackColor}`}>Our Articles</h1>
                        </div>
                        <div> 
                            <p>Articles coming soon!</p>
                        </div>
                    </div>
                    <Divider className={classes.horizontalDivider}/>
                    <div className={`${classes.alignLeft} ${classes.column} ${classes.fullWidth} ${classes.bottomSpacing}`}>
                        <div className={`${classes.row} ${classes.rightSpacing} ${classes.bottomSpacing} ${classes.center}`}>
                            <Divider orientation="vertical" className={`${classes.verticalDivider} ${classes.blackBackground}`}/>
                            <h1 className={`${classes.header} ${classes.blackColor}`}>Our Videos</h1>
                        </div>
                        <div> 
                            <p>Videos coming soon!</p>
                        </div>
                    </div>
                </div>
            </Container>
            <Contact />
        </>
    );
}

export default TNTAcademy; 