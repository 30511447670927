import React from "react";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Contact from "./landing/contact.js";

const useStyles = makeStyles((theme) => ({
  networkingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    height: "100%",
    marginTop: "10vh",
    flexDirection: "column",
  },
  contentContainer: {
    textAlign: "center",
  },
  containerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    alignItems: "center",
    margin: "30px 0",
  },
  imageStyles: {
    width: "300px",
    margin: "0 50px",
  },
  icon: {
    fontSize: "60px",
  },
  greyColor: {
    color: "#005678",
  },
  fontBold: {
    fontWeight: "bold",
  },
  dividerStyle: {
    backgroundColor: "#00B192",
    width: "10vw",
    height: "0.5vw",
    borderRadius: "5px",
    margin: "20px 0",
  },
  sponsorImages: {
    width: "150px",
    margin: "0 50px",
  },
  title: {
    fontSize: "36px",
  },
  padding: {
    padding: "5px 30px",
  },
  "@media (max-width: 650px)": {
    containerRow: {
      flexDirection: "column",
    },
    imageStyles: {
      padding: "10px",
    },
  },
}));

function Networking() {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container maxWidth={false} className={classes.networkingContainer}>
        <h1 className={classes.title}>Network with us!</h1>
        <p className={classes.padding}>
          Get involved with TNT by diving into our active networking scene!
          Whether you’re an undergraduate or graduate student, our networking
          nights provide the perfect environment to connect with fellow
          students, academic mentors and even industry partners fostering
          relationships that extend beyond the classroom. But networking with
          TNT doesn’t stop there – we’re connected with numerous companies
          through sponsorships and collaborations, offering you exclusive
          insights into the industry landscape. We also work with a variety of
          clubs and universities through collaborative events like HARD Hack and
          the California Neurotech Conference, ensuring you stay part of the
          action. Join TNT, where networking isn’t just a buzzword; it’s a
          dynamic, ongoing experience that opens doors to diverse connections
          and opportunities.
        </p>
        <Divider className={classes.dividerStyle} />
        <h1 className={classes.title}>Our Sponsors</h1>
        <div className={classes.contentContainer}>
          <div className={classes.containerRow}>
            <a href="https://neurotechx.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/sponsors/neurotechx.png"
                alt="NeurotechX"
                className={classes.imageStyles}
              />
            </a>
            <a href="http://www.ece.ucsd.edu/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/sponsors/ucsd_jacobs.png"
                alt="UCSD Jacobs School of Engineering"
                className={classes.imageStyles}
              />
            </a>
          </div>
          <div className={classes.containerRow}>
            <a
              href="https://wearablesensing.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/sponsors/wearable_sensing.png"
                alt="Wearable Sensing"
                className={classes.imageStyles}
              />
            </a>
            <a href="https://openbci.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/sponsors/openBCI.png"
                alt="OpenBCI"
                className={classes.imageStyles}
              />
            </a>
          </div>
          <div className={classes.containerRow}>
            <a href="https://mbraintrain.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/sponsors/mbt_logo.png"
                alt="mbt"
                className={classes.imageStyles}
              />
            </a>
          </div>
        </div>
        <div className={classes.spacer}></div>
        <Divider className={classes.dividerStyle} />
        <h1 className={classes.title}>Our Collaborators</h1>
        <div className={classes.contentContainer}>
          <div className={classes.containerRow}>
            <a href="https://neuralink.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/neuralink.png"
                alt="Neuralink"
                className={classes.sponsorImages}
              />
            </a>
            <a href="https://www.ds3ucsd.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/ds3.png"
                alt="Data Science Student Society"
                className={classes.sponsorImages}
              />
            </a>
            <a
              href="https://neatlabs.ucsd.edu/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/neatlabs.png"
                alt="Neatlabs"
                className={classes.sponsorImages}
              />
            </a>
            <a href="https://acmucsd.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/acm.png"
                alt="ACM"
                className={classes.sponsorImages}
              />
            </a>
          </div>
          <div className={classes.containerRow}>
            <a href="https://tnel.ucsd.edu/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/TNEL.png"
                alt="Translational Neuroengineering Lab"
                className={classes.sponsorImages}
              />
            </a>
            <a href="https://hkn.ucsd.edu/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/hkn.png"
                alt="HKN"
                className={classes.sponsorImages}
              />
            </a>
            <a href="https://ieeeucsd.org/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/IEEE.png"
                alt="IEEE"
                className={classes.sponsorImages}
              />
            </a>
            <a href="https://www.digikey.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/digi-key.png"
                alt="Digi-sKey"
                className={classes.sponsorImages}
              />
            </a>
          </div>
          <div className={classes.containerRow}>
            <a
              href="https://isn.ucsd.edu/index.php"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/ISN.png"
                alt="Integrated Systems Neuroengineering"
                className={classes.sponsorImages}
              />
            </a>
            <a
              href="https://ubicucsd.github.io/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/UBIC.png"
                alt="UBIC"
                className={classes.sponsorImages}
              />
            </a>
            <a href="https://neurotechx.com/" rel="noreferrer" target="_blank">
              <img
                src="images/networking/collaborators/neurotechx.png"
                alt="NeurotechX"
                className={classes.sponsorImages}
              />
            </a>
            <a
              href="https://www.cruxucla.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/CruX_UCLA.png"
                alt="CruX UCLA"
                className={classes.sponsorImages}
              />
            </a>
          </div>
          <div className={classes.containerRow}>
            <a
              href="https://neurotech.berkeley.edu/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/ucb_neurotech.png"
                alt="UC Berkeley NeuroTech"
                className={classes.sponsorImages}
              />
            </a>
            <a
              href="https://neuroengineering.ucdavis.edu/neurotechdavis"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/ucd_neurotech.png"
                alt="NeuroTech @ UC Davis"
                className={classes.sponsorImages}
              />
            </a>
            <a
              href="https://neurotech.ucsc.edu/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="images/networking/collaborators/ucsc_neurotech.png"
                alt="UC Santa Cruz NeuroTech"
                className={classes.sponsorImages}
              />
            </a>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
}

export default Networking;
