import { Container, Select } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import TeamCard from "./team-card";
import teamData from "./teamData.json";
import Contact from "../landing/contact.js";
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
    contactBg: {
        minHeight: '100vh',
        width: '100vw'
    },
    column: {
        paddingTop: '75px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
    },
    column2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        width: '100%',
        paddingTop: '20px', 
    },
    largeText: {
        fontSize: '70px',
        paddingBottom: '30px'
    },
    dividerStyle: { 
        backgroundColor: '#00B192',
        width: '10vw',
        height: '0.5vw', 
        borderRadius: '5px', 
        margin: '100px 0'
    }, 
    fontStyles: { 
        fontFamily: theme.typography.fontFamily
    }
}));

export default function Team() {
    const classes = useStyles()

    const [state, setState] = React.useState("2023-2024");

    function handleChange(e) {
        setState(e.target.value)
    }

    React.useEffect(() => {
        document.getElementById("none").scrollIntoView()
    })
    return(
        <>
        <div id="none"></div>
        <Container maxWidth={false} className={`${classes.contactBg} ${classes.column}`}>
            <div className={classes.largeText}>Meet the Team!</div>
            <div className={classes.fullWidth}>
                <Select native onChange={(e) => handleChange(e)} >
                {/*         
                    List of years in the drop-down menu
                  */}
                <option aria-label="None" value="2023-2024" >2023-2024</option>
                <option aria-label="None" value="2022-2023" >2022-2023</option>
                    <option aria-label="None" value="2021-2022" >2021-2022</option>
                    <option value="2020-2021" >2020-2021</option>
                    <option value="2019-2020">2019-2020</option>
                </Select>
            </div>
            <div className={classes.column2}>
                {
                    state && teamData[state].map((data, index) =>
                        <div key={index} className={classes.column2}>
                            <h2 className={classes.fontStyles}>{data.groupName}</h2>
                            <div className={classes.row}>
                            {
                                data.members.map((info, index) =>
                                    <TeamCard key={index} name={info.name} img={info.src} position={info.position} link={info.linkedIn} major={info.major}/>
                                )
                            }
                            </div>
                            {index !== teamData[state].length - 1 && <Divider className={classes.dividerStyle}/>}
                        </div>
                    )
                }
            </div>
        </Container>
        <Contact />
        </>
    )
}