import React, { useEffect } from "react";
// import About from "./about/about";
import Contact from "./contact";
import GetInvolved from "./get-involved";
import Home from "./home";
import { useParams } from "react-router-dom";

export default function Landing() {
    const params = useParams()

    useEffect(() => {
        if (params.sectionId) {
            document.getElementById(params.sectionId).scrollIntoView()
        } else {
            document.getElementById("home").scrollIntoView()
        }
    })

    //TODO: Add Events section under About section.
    return (
        <>
            <div id="home"></div>
            <Home />
            <div id="about"></div>
            <GetInvolved />
            <div id="contact"></div>
            <Contact />
        </>
    )
}